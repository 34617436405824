import socket from "./socket";

export default function ConnectionManager(props) {
    function connect() {
        socket.connect();
    }

    function disconnect() {
        socket.disconnect();
    }

    return (
        <>
            {!props.isConnected ? (
                <button onClick={connect}>Connect</button>
            ): (
                <></>
            )}


        </>
    );
}
