import './App.css';
import {useCallback, useEffect, useState} from "react";
import socket from "./socket";
import ConnectionManager from "./ConnectionManager";
import logo from './logo.png';
import {makeid} from "./Makeid";
import QRCode from "react-qr-code";
import moment from "moment/moment";



function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [qrCode, setQrCode] = useState('');
  const [copied, setCopied] = useState(-1);


    useEffect(() => {
      const qrVal = localStorage.getItem("QRVAL");
      if(qrVal){
          setQrCode(qrVal);
      }else{
          const id = makeid(5);
          setQrCode(id);
          localStorage.setItem('QRVAL', id);
      }
    }, []);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value) {
      console.log("foo", value)

        if(value !== null){




        const spValue = value.split("---");
        console.log(spValue);

        if(spValue.length > 1){

            if(spValue[0] !== ''){

                if(spValue[0] === qrCode){
                    setFooEvents((previous) => {
                        if(previous.length > 14){
                            previous.pop();
                        }

                        return [{val: spValue[1], time: moment().format("HH:mm")}, ...previous];

                    });
                }
            }

        }
        }


    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onFooEvent);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onFooEvent);
    };
  }, [qrCode]);

  const copyToClip = useCallback(async (text, index) => {
      await navigator.clipboard.writeText(text);

      setCopied(index);
      setTimeout(() => {
          setCopied(-1)
      }, 500);


  }, []);

  return (
      <div>

          <div style={{position: "absolute", top: 10, left: 10}}>
              <QRCode
                  bgColor={"transparent"}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={qrCode}
                  viewBox={`0 0 256 256`}
              />
          </div>


        <div className="Hello">
          <img width="200" alt="icon" src={logo} />
        </div>

        <p>Server State: {`${isConnected ? "Connected" : "Disconnected"}`}</p>


        <p style={{fontSize: 22, fontWeight: "bold"}}>
          Read Items:
        </p>
        <div style={{marginBottom: 20}}>
          <ul style={{ listStyle: "none", padding: 0, margin: 0, maxHeight: '50vh', overflow: "scroll", }}>
            {fooEvents.map((event, index) => {

              if(index === 0) {
                return  <li onClick={() => copyToClip(event.val, index)} key={index} style={{fontWeight: "bold", fontSize: 35, marginBottom: 10, cursor: "pointer"}}>{event.time}: <i style={{marginRight: 20}}></i> {event.val} {copied === index && "✅"} </li>;
              }else {
                return <li onClick={() => copyToClip(event.val, index)} key={index}
                           style={{marginBottom: 5, cursor: "pointer", color: "#00000057"}}>{event.time}:<i
                    style={{marginRight: 20}}></i> {event.val} {copied === index && "✅"}  </li>;
              }

            })}
          </ul>
        </div>


        <ConnectionManager isConnected={isConnected} />
      </div>
  );
}


export default App;
